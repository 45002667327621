import { Avatar, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { sprinkles } from "@pomebile/primitives-web"
import { StickyBottom } from "../../components/StickyBottom"
import {
  Callout,
  ChevronIcon,
  PHFlagIcon,
  PHFlagRectIcon,
  PomeloCardIcon,
  VSpace,
} from "@pomebile/design-system"
import { DeclineDialog } from "./DeclineDialog"
import { useEffect, useReducer } from "react"
import { RatesAndFeesDialogComponent } from "./RatesAndFeesDialog"
import { TermsAndConditionsDialog } from "../../components/TermsAndConditionsDialog"
import { CardConfiguration, UserResponse } from "@pomebile/pomelo-service-api"
import { SubmitButton } from "../../components/Form/FormSubmitButton"
import { CardColors } from "@pomebile/primitives/tokens"
import { MtpRatePromo, NewAccountResponse } from "../../api/webRoutes"
import { useSubmit } from "../../hooks/useSubmit"
import { ScreenFrame } from "../../components/ScreenFrame"
import { DeclineReasonDialog } from "../DeclineReasonDialog"
import { useFeatureFlag } from "../../utils/featureFlag.tsx"
import { createLoggingContext } from "../../sharedShellLogic.tsx"
import { OfferDetailItemRow } from "../../components/OfferDetailItemRow.tsx"

type CardVariants = "pink" | "green" | "white"
export type Card = {
  cardConfigId: string
  color: CardColors
}

const DEFAULT_CARD_OPTIONS: Readonly<Record<CardVariants, Card>> = {
  pink: {
    cardConfigId: "pom_pink",
    color: "pink",
  },
  green: {
    cardConfigId: "pom_green",
    color: "green",
  },
  white: {
    cardConfigId: "pom_white",
    color: "white",
  },
}

type AcceptResult =
  | {
      tag: "accepted"
      productGroupIdent: string
      cards: Card[]
      updatedMtpRatePromo?: MtpRatePromo
    }
  | {
      tag: "declined"
    }
  | {
      tag: "noProductGroupIdent"
    }

export interface UnsecuredOfferProps {
  api: {
    openAccount: () => Promise<NewAccountResponse>
    fetchCardConfigs: () => Promise<CardConfiguration[]>
    declineReason: (reason: string) => Promise<UserResponse>
  }
  mtpRatePromo?: MtpRatePromo
  approvedLimit: number
  onDone: (result: AcceptResult) => void
}

type DialogKind =
  | "declineDialog"
  | "ratesAndFeesDialog"
  | "termsAndConditionsDialog"
  | "declineReasonDialog"
type Ev = {
  action: "close" | "open"
  dialog: DialogKind
}
type DialogState = {
  dialog: DialogKind | undefined // undefined expresses that no dialog is opened
  prevDialog: DialogKind | undefined
}

const updateDialogState = (prevState: DialogState, ev: Ev): DialogState => {
  const { action, dialog } = ev

  if (action === "open") {
    return { prevDialog: prevState.dialog, dialog }
  }

  if (prevState.dialog !== undefined && action === "close" && dialog === prevState.dialog) {
    if (dialog === "termsAndConditionsDialog") {
      return { ...prevState, dialog: prevState.prevDialog }
    }

    return { ...prevState, dialog: undefined }
  }

  return prevState
}

export const UnsecuredOffer = ({
  api: { fetchCardConfigs, openAccount, declineReason },
  mtpRatePromo,
  approvedLimit,
  onDone,
}: UnsecuredOfferProps) => {
  const { logEvent } = createLoggingContext()
  const { isLoading, enabledFlags } = useFeatureFlag()
  const displayPromoExpiry = enabledFlags.includes("DISPLAY_PROMO_EXPIRY")

  useEffect(() => {
    if (isLoading) {
      return
    }
    logEvent("Viewed Unsecured Offer", { displayPromoExpiry })
  }, [displayPromoExpiry, logEvent, isLoading])

  const [{ dialog }, send] = useReducer(updateDialogState, {
    dialog: undefined,
    prevDialog: undefined,
  })
  const [submit, status] = useSubmit(async () => {
    try {
      const { productGroupIdent, updatedMtpRatePromo } = await openAccount()

      const cardConfigs = await fetchCardConfigs() // This request depends on the above request to be successful
      const userCardConfigIds = cardConfigs.map((config) => config.configId)
      // Filter out possible card color options based on the card configurations response we get from backend
      const filteredCardOptions = Object.values(DEFAULT_CARD_OPTIONS).filter((card) =>
        userCardConfigIds.includes(card.cardConfigId),
      )
      const cards: Card[] = filteredCardOptions.length
        ? filteredCardOptions
        : Object.values(DEFAULT_CARD_OPTIONS)

      onDone({
        tag: "accepted",
        cards,
        productGroupIdent,
        updatedMtpRatePromo,
      })
    } catch (ResponseValidationError) {
      onDone({
        tag: "noProductGroupIdent",
      })
    }
  })

  const openDialog = (dialog: DialogKind) => {
    send({ action: "open", dialog })
  }

  const closeDialog = (dialog: DialogKind) => {
    send({ action: "close", dialog })
  }

  const ratesAndFeesLink = (
    <Link as="div" onClick={() => openDialog("ratesAndFeesDialog")}>
      <HStack justifyContent="center" alignItems="center">
        <Txt variant="button2" color="text-brand">
          View Rates and Fees
        </Txt>
        <ChevronIcon fill="icon-brand" direction="right" />
      </HStack>
    </Link>
  )

  const moneyTransferPromo = mtpRatePromo

  const promoDetailCallout = moneyTransferPromo && (
    <VStack width="full">
      <Callout variant="success">
        <VStack alignItems="center" gap="xs2">
          <HStack alignItems="center" width="unset">
            <Txt variant="subtitle2" color="text-default">
              $1 =&nbsp;
            </Txt>
            <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
              <PHFlagRectIcon width={16} height={12} />
            </div>
            <Txt color="text-default" variant="subtitle2">
              &nbsp;₱{moneyTransferPromo.rate}
            </Txt>
          </HStack>
          <VStack alignItems="center">
            <Txt color="text-default" variant="caption" textAlign="center">
              For your first transfer up to ${moneyTransferPromo.limit}
              {displayPromoExpiry && " within 7 days of accepting this plan."}
            </Txt>
            <Txt variant="caption">
              <Link
                inline
                color="neutral"
                decoration="underline"
                onClick={() => openDialog("termsAndConditionsDialog")}
              >
                Terms and Conditions
              </Link>
            </Txt>
          </VStack>
        </VStack>
      </Callout>
    </VStack>
  )

  const creditLimit = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(approvedLimit)

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between">
        <VStack height="full" gap="xl4" padding={{ bottom: "xl" }}>
          <VStack>
            <Txt variant="subtitle2" color="text-emphasis" textAlign="center">
              You're Approved
            </Txt>
            <Txt variant="headline2" textAlign="center">
              Pay Later Plan
            </Txt>
            <VSpace height="xs" />
            <Txt variant="body1" textAlign="center">
              Send money to your loved ones in the Philippines and pay later with Pomelo Mastercard®
            </Txt>
          </VStack>

          <HStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                Credit Limit
              </Txt>
              <Txt variant="headline3" color="text-default">
                {creditLimit}
              </Txt>
            </VStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                APR
              </Txt>
              <Txt variant="headline3" color="text-default">
                None
              </Txt>
            </VStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                Annual Fees
              </Txt>
              <Txt variant="headline3" color="text-default">
                None
              </Txt>
            </VStack>
          </HStack>

          <VStack gap="lg">
            {moneyTransferPromo && (
              <OfferDetailItemRow
                banner={displayPromoExpiry ? "Limited Time Promotion" : undefined}
                icon={<PHFlagIcon width={24} height={24} />}
                title="Send money to the Philippines"
                subtitle="Take advantage of zero transfer fees when you send to GCash or Bank Accounts with Pomelo Mastercard\u00AE."
                bottomElement={promoDetailCallout}
              />
            )}
            <OfferDetailItemRow
              icon={<PomeloCardIcon />}
              title="Pay with Pomelo Mastercard®"
              subtitle="Pay for money transfers later with zero interest, and make purchases anywhere Mastercard® is accepted."
              bottomElement={ratesAndFeesLink}
            />
            <Txt variant="body2" color="text-caption" textAlign="center">
              By accepting the offer you authorize Pomelo to do a full credit inquiry which may
              affect your credit score.
            </Txt>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <SubmitButton submit={submit} status={status}>
              Accept Plan
            </SubmitButton>
            <Link onClick={status === "idle" ? () => openDialog("declineReasonDialog") : undefined}>
              <Txt textAlign="center" color="text-brand" as="p">
                Decline
              </Txt>
            </Link>
          </VStack>
        </StickyBottom>

        <DeclineReasonDialog
          isOpen={dialog === "declineReasonDialog"}
          onClose={() => closeDialog("declineReasonDialog")}
          onDecline={() => openDialog("declineDialog")}
          api={declineReason}
        />

        <DeclineDialog
          isOpen={dialog === "declineDialog"}
          onClose={() => closeDialog("declineDialog")}
          onDecline={() => {
            onDone({ tag: "declined" })
          }}
        />
        <RatesAndFeesDialogComponent
          isOpen={dialog === "ratesAndFeesDialog"}
          onClose={() => closeDialog("ratesAndFeesDialog")}
          onOpenTermsAndConditions={() => openDialog("termsAndConditionsDialog")}
        />

        <TermsAndConditionsDialog
          open={dialog === "termsAndConditionsDialog"}
          showMoneyTransferPromo={!!moneyTransferPromo}
          onClose={() => closeDialog("termsAndConditionsDialog")}
        />
      </VStack>
    </ScreenFrame>
  )
}
